import React from "react";
import { Card, CardActionArea, CardContent, Typography,Box, Stack} from "@mui/material";
import { Link } from "gatsby";
const Discover = ({ title, type, text, photo, slug }) => {
    return (
        <>
    
                    <Card variant="outlined" sx={{all:"unset",minHeight: "345px"}} >
                        <CardActionArea component={Link} to={slug} sx={{display:"flex",flexDirection:{xs:"column",md:"row"}}}>
                            <Stack alignItems="flex-start" paddingTop="30px" paddingLeft="20px" paddingRight="20px" width={{xs:"100%",md:"60%"}}>
                            <Typography gutterBottom component="div" sx={{textTransform:"uppercase",fontWeight:"600",fontSize:"18px",color:"#1f2937"}}>
                                    {title}
                                </Typography>
                            {/* <Box component="img" sx={{height:"50px",objectFit:"contain"}} src={logo} alt="Company Logo" /> */}
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{textTransform:"uppercase",fontWeight:"600",fontSize:"15px",color:"#4f46e5"}}>
                                    {type}
                                </Typography>
                                <Typography variant="body2" sx={{fontSize:"16px",color:"#3d3d3d",minHeight:"70px",whiteSpace:"normal"}}>
                                    {text}
                                </Typography>
                            </CardContent>
                            </Stack>
                            <Box component="img" sx={{objectFit:"cover",width:"100%",height:"200px"}} src={photo} alt="Company Logo"/>
                        </CardActionArea>
                    </Card>

        </>
    );
}

export default Discover;