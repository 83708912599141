import React, { useEffect } from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from "react-helmet"
import Discover from '../components/casestudy'
import { Grid, Stack } from "@mui/material";
import cardsData from '../data/aboutwork';
export default function CaseStudies() {
  useEffect(() => {
    function initApollo() {
      var n = Math.random().toString(36).substring(7);
      var o = document.createElement("script");
      o.src = "https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=" + n;
      o.async = true;
      o.defer = true;
      o.onload = function () {
        window.trackingFunctions.onLoad({ appId: "66312ccf53fe5801c7e5189f" });
      };
      document.head.appendChild(o);
    }
    initApollo();
  }, []);
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>C4Scale - Case studies</title>
          <meta name="description" content="Privacy policies at C4Scale" />
        </Helmet>
        <Stack className='bg-indigo-600' sx={{padding:{xs:"60px 30px",md:"80px 150px"}}}>
      <h1 className='text-3xl md:text-4xl font-semibold text-[#fff]'>
      The ambitions <br/><span className='relative top-4'>
      we've built.</span>
      </h1>
    </Stack>
        <Stack sx={{padding:{xs:"30px",md:"50px"},gap: "50px"}} justifyContent="center" alignItems="center">
          {cardsData.map((card, index) => (
            <Stack key={index} style={{ display: "inline-block", background: index % 2 === 0 ? "#fbfafb" : "#e7edfb", padding: "0px", margin: "0px",width:"70%"}}>
              <Discover {...card} />
            </Stack>
          ))}
        </Stack>
    </Layout>
  )
}
